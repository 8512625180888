import { Avatar, FilledInput, InputAdornment, Popover } from '@mui/material';
import { SetStateAction, useState } from "react";
import "./SearchableDropdown.scss";
import { DropdownItemProps } from '../Mui/CustomAutocomplete/CustomAutocomplete';
import { CancelRounded, Search } from '@mui/icons-material';
import { stringAvatar } from '../../helpers/helperfunctions';

function SearchableDropdown(props: {
    onSelect: (selectedValue: DropdownItemProps) => void; initialDetails: DropdownItemProps[];
    id: string | undefined;
    open: boolean;
    anchorEl: any;
    handleClose: any,
    selectedItems: DropdownItemProps[]
}) {

    const [searchField, setSearchField] = useState<string>('');

    const handleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setSearchField(e.target.value);
    };

    function SearchList(prop: { filteredPersons: any }) {

        const filtered = prop.filteredPersons.map((value: DropdownItemProps) => <Card key={value.id.toString()} item={value} />);

        return (<div>{filtered}</div>);
    }

    function Card(prop: { item: DropdownItemProps; }) {
        return (
            <div onClick={() => props.onSelect(prop.item)} className="item">
                <span className='avatar-container'>
                    <Avatar {...stringAvatar(prop.item.label)} />
                    {props.selectedItems.filter(t => t.id === prop.item.id).length > 0 && (
                        <CancelRounded className="cancel-btn" color="error" />
                    )}
                </span>
                <span>{prop.item.label}</span>
            </div>
        )
    }

    const Scroll = (props: { children: any }) => {
        return (
            <div style={{ overflowY: 'scroll', maxHeight: '20vh' }}>
                {props.children}
            </div>
        );
    }

    const filteredSearch = props.initialDetails.filter(data => {
        return (data.label.toLowerCase().includes(searchField.toLowerCase()));
    });

    function searchList() {
        return (
            <Scroll>
                <SearchList filteredPersons={filteredSearch} />
            </Scroll>
        );
    }

    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
        >
            <div className="drp-search-container">
                {<FilledInput id="filled-adornment" disableUnderline={true} type={"text"} placeholder={"Search"}
                    classes={{ root: "search-container", input: "input" }} onChange={handleChange}
                    startAdornment={
                        <InputAdornment classes={{ root: "img-container" }} position="end">
                            <Search className='search-icon' />
                        </InputAdornment>
                    }
                />}
                {searchList()}
            </div>
        </Popover>
    )
}


export default SearchableDropdown