import React, { useEffect, useState } from 'react'
import Stepper from '../Stepper/Stepper';
import CustomTextField from '../Mui/CustomTextField/CustomTextField';
import CustomAutocomplete from '../Mui/CustomAutocomplete/CustomAutocomplete';
import { CustomSelect } from '../Mui/CustomSelect/CustomSelect';
import { ICreateLeadData, ICreateLeadUser, ILeadValidation, IStepperItem } from '../../helpers/Interfaces';
import { pointOfReferrals } from '../../helpers/constants';
import { generateUniqueKey, getDefaultValueInDropdown, getUsersFromList } from '../../helpers/helperfunctions';
import { Checkbox, FormControlLabel } from '@mui/material';
import { CustomMuiPhone } from '../Mui/CustomPhoneNumber/CustomPhoneNumber';
import dayjs from 'dayjs';
import 'react-international-phone/style.css';
import { t } from 'i18next';

type CustomerInformationProps = {
    divARef: React.MutableRefObject<HTMLDivElement | null>
    divBRef: React.MutableRefObject<HTMLDivElement | null>
    stepperSteps: IStepperItem[]
    leadUser: ICreateLeadUser,
    organizationUsers: ICreateLeadUser[],
    lead: ICreateLeadData,
    leadValidation: ILeadValidation
    setLeadsUser: CallableFunction
    setLeads: CallableFunction,
    edit?: boolean
}

const CustomerInformation = ({ divARef, divBRef, stepperSteps, leadUser, setLeadsUser, leadValidation, lead, setLeads, organizationUsers, edit }: CustomerInformationProps) => {
    const [defaultUserName, setDefaultUserName] = useState(getDefaultValueInDropdown(getUsersFromList(organizationUsers), leadUser.name));

    useEffect(() => {
        const value = getDefaultValueInDropdown(getUsersFromList(organizationUsers), leadUser.name) as any
        setDefaultUserName(value);

        if (edit && !lead.userId) {
            const newLead = lead;
            newLead.userId = leadUser.id;
            setLeads({ ...newLead });
        }

    }, [])

    return (
        <div className="middle">
            <Stepper activeStep={1} divARef={divARef} divBRef={divBRef} steps={stepperSteps} />
            <div className="rht flex-0-a">
                <div className="lead-rht-inner">
                    <div ref={divBRef} className="scroll-div">
                        <h4 className="header primary-font fz-16 ttu fw-400">{t('customerInformation')}</h4>
                        <div className="form-group mb-30">
                            <CustomTextField value={lead.title} defaultValue={lead.title} label={'Title'} size="small" helperText={leadValidation.email.message ?? "Invalid input, enter a valid value."}
                                type='email'
                                onChange={(val) => {
                                    let newLead = lead;
                                    newLead.title = val.target.value
                                    setLeadsUser({ ...newLead });
                                }} />
                        </div>
                        <div className="form-outer mb-40">
                            <div className="form-group">
                                <CustomAutocomplete freeSolo={!edit ?? false} required error={leadValidation.name} helperText={t('fieldRequired')} label={t('name')} options={getUsersFromList(organizationUsers)} defaultValue={defaultUserName} onSelect={(value) => {
                                    if (!value) {
                                        setLeadsUser({ name: '', phoneNumber: '', email: '', nationality: '' })
                                        return
                                    };
                                    if (value.id === '') {
                                        let newLead = leadUser;
                                        newLead.id = undefined;
                                        newLead.name = value.label;
                                        setLeadsUser({ ...newLead });
                                    } else {
                                        const user = organizationUsers.find(t => t.id === value.id);
                                        if (user) {
                                            setLeadsUser(user);
                                            const newLead = lead;
                                            newLead.userId = user.id;
                                            setLeads({ ...newLead });
                                        }
                                    }
                                }} />
                            </div>
                            <div className="form-group">
                                <CustomMuiPhone
                                    disabled={edit ?? false}
                                    value={leadUser.phoneNumber ?? ''} error={leadValidation.phoneNumber} required defaultValue={leadUser.phoneNumber} label={t('phoneNumber')} size="small" helperText="Invalid input, enter a valid value."
                                    onChange={(phone) => {
                                        let newLead = leadUser;
                                        if (phone !== leadUser.phoneNumber) {
                                            newLead.id = undefined;
                                        }
                                        newLead.phoneNumber = phone;
                                        setLeadsUser({ ...newLead });
                                    }}
                                />

                            </div>
                            <div className="form-group">
                                <CustomTextField value={leadUser.email} error={leadValidation.email.validation} required defaultValue={leadUser.email} label={t('emailAddress')} size="small" helperText={leadValidation.email.message ?? "Invalid input, enter a valid value."}
                                    disabled={edit ?? false}
                                    type='email'
                                    onChange={(val) => {
                                        let newLead = leadUser;
                                        newLead.id = undefined;
                                        newLead.email = val.target.value
                                        setLeadsUser({ ...newLead });
                                    }} />
                            </div>
                            <div className="form-group">
                                <CustomTextField value={leadUser.nationality} error={leadValidation.nationality} required defaultValue={leadUser.email} label={t('nationality')} size="small" helperText="Invalid input, enter a valid value."
                                    disabled={edit ?? false}
                                    onChange={(val) => {
                                        let newLead = leadUser;
                                        newLead.id = undefined;
                                        newLead.nationality = val.target.value
                                        setLeadsUser({ ...newLead });
                                    }} />
                            </div>
                        </div>
                        <h4 className="header primary-font fz-16 ttu fw-400">{t('referralInformation')}</h4>
                        <div className="form-outer mb-20">
                            <div className="form-group">
                                <CustomSelect
                                    helperText="Invalid input, enter a valid value."
                                    label={t('pointOfReferral')}
                                    defaultValue={getDefaultValueInDropdown(pointOfReferrals, lead.pointReferral)}
                                    options={pointOfReferrals}
                                    onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.pointReferral = value?.label;
                                        setLeads({ ...newLead });
                                    }}
                                />
                            </div>
                            {lead.pointReferral === t('referringPartner')
                                ? (<div className="form-group">
                                    <CustomTextField defaultValue={lead.referringPartner} label={t('referringPartner')} size="small" helperText={t('invalidValue')}
                                        type='text'
                                        onChange={(val) => {
                                            let newLead = lead;
                                            newLead.referringPartner = val.target.value
                                            setLeads({ ...newLead });
                                        }} />
                                </div>)
                                : (<></>)}
                        </div>
                        {lead.pointReferral === t('tradeShow')
                            ? (<div className="form-outer">
                                <FormControlLabel control={<Checkbox onChange={(val) => {
                                    let newLead = lead;
                                    newLead.task = [];
                                    if (val.target.checked) {
                                        newLead.task?.push({
                                            id: generateUniqueKey(),
                                            description: 'Call the Client',
                                            status: 'PENDING',
                                            dueDate: dayjs(new Date())
                                        })
                                    } else {
                                        newLead.task = [];
                                    }
                                    setLeads({ ...newLead });
                                }} />} label={t('callClient')} />
                            </div>)
                            : (<></>)}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerInformation