import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { Search } from '@mui/icons-material';
import './CustomAutocomplete.scss'
import '../MuiBaseStyle.scss'
import { t } from "i18next";
import { ReactElement, useState } from "react";

export type DropdownItemProps = {
    label: string,
    id: string | Number
    icon?: ReactElement
}
const filter = createFilterOptions<DropdownItemProps>();

export default function CustomAutocomplete(props: {
    options: DropdownItemProps[], label: String, onSelect: (value: DropdownItemProps | undefined | any) => void,
    freeSolo?: boolean, defaultValue?: DropdownItemProps, required?: boolean, helperText?: string, error?: boolean, disabled?: boolean,
}) {
    const [inputValue, setInputValue] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [_freeSolo, set_freeSolo] = useState<boolean | undefined>(props.freeSolo);

    const getOptions = () => {
        const list = props.options.map(item => ({ ...item, label: t(item.label) }));
        return list;
    }

    return (
        <Autocomplete
            popupIcon={<Search />}
            fullWidth
            size="small"
            freeSolo={_freeSolo}
            disabled={props.disabled ?? false}
            classes={{ root: "custom-mui-field custom-auto-complete" }}
            value={props.defaultValue}
            defaultValue={props.defaultValue}
            onChange={(event, value: any) => {
                props.onSelect(value ?? undefined);
                setIsOpen(false);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                const isExisting = getOptions().some(option => option.label.toLowerCase().includes(newInputValue.toLowerCase()));
                setInputValue(newInputValue);
                if ((newInputValue.length >= 3) && isExisting) {
                    setIsOpen(true);
                } else {
                    set_freeSolo(true);
                    props.onSelect({
                        id: '',
                        label: newInputValue,
                    })
                    setIsOpen(false);
                }
            }}
            open={isOpen}
            sx={{
                "& .MuiAutocomplete-popupIndicator": { transform: "none" },
            }}
            filterOptions={(options, params) => {
                const { inputValue } = params;

                if (inputValue.length < 3) {
                    return [];
                }

                const filtered = filter(options, params);

                return filtered;
            }}
            options={getOptions()}
            renderInput={(params) => <TextField required={props.required} {...params} label={props.label}
                error={props.error} helperText={props.error ? props.helperText : null} />}
        />
    )
}