import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './CustomDateInput.scss'
import '../MuiBaseStyle.scss'
import dayjs from 'dayjs'


function CustomDateInput(props: { label: string, maxYearsBack?: number, disablePast?: boolean, disableFuture?: boolean, error?: boolean, helperText?: string, defaultValue?: string, onChange?: (value: dayjs.Dayjs | undefined | any) => void }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker maxDate={props.maxYearsBack ? dayjs(new Date()).subtract(props.maxYearsBack, 'year') : undefined} disableFuture={props.disableFuture} disablePast={props.disablePast ?? false} onChange={props.onChange} defaultValue={dayjs(props.defaultValue)} label={props.label} slotProps={{ textField: { fullWidth: true, size: 'small', classes: ({ root: "custom-mui-field custom-date-input" }), helperText: props.error ? props.helperText : null, error: props.error } }} />
        </LocalizationProvider>
    )
}

export default CustomDateInput