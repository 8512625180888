import React, { useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import "./Profile.scss"
import Dashboard from '../../components/Dashboard/Dashboard';
import BorderlessButton from '../../components/ButtonWithoutBorder/ButtonWithoutBorder';
import EditButton from '../../assets/images/profile/EditButton';
import EditProfile from '../../components/EditProfile/EditProfile';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import { ICustomFile, ILog, IUser } from '../../helpers/Interfaces';
import BorderAdd from '../../assets/images/profile/BorderAdd';
import FileTypeIcon from '../../components/FileTypeIcon/FileTypeIcon';
import FileTable from '../../components/FileTable/FileTable';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import ApiService from '../../services/ApiService';
import { extractUsernameFromLink, formatDate } from '../../helpers/helperfunctions';
import FirebaseFunctions from '../../helpers/firebaseFunctions';
import FileUpload from '../../components/FileUpload/FileUpload';



const Profile = () => {
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState<ILog[]>([]);
    const divBRef = useRef<HTMLDivElement | null>(null);
    const [userData, setUserData] = useState({} as IUser)
    const [media, setMedia] = useState<ICustomFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const apiService = new ApiService();
    const firebaseFunctions = new FirebaseFunctions();


    useEffect(() => {
        setLoading(true);
        getUserData();
        setLoading(false);
    }, [])

    useEffect(() => {
        setLoading(true);
        getUserData();
        setLoading(false);
    }, [open])

    const handleDocumentChange = async (fileList: FileList) => {
        if (fileList) {
            console.log(fileList)
        }
    };

    const handleProfilePicChange = (fileList: FileList) => {
        const file = fileList[0];
        if (file) {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onloadend = async () => {
                try {
                    setLoading(true);
                    const base64String = reader.result as string;

                    const profileUrl = await firebaseFunctions.uploadBase64Image(base64String, userData.email!);

                    setUserData((prevUser) => {
                        const updatedUserData = {
                            ...prevUser,
                            photoURL: profileUrl,
                        };
                        return updatedUserData;
                    });

                    const response = await apiService.editUserData({
                        ...userData,
                        photoURL: profileUrl,
                    });
                    setLoading(false);
                    console.log(response);
                } catch (error) {
                    setLoading(false);
                    console.error("Error uploading profile picture:", error);
                }
            };

            reader.onerror = () => {
                setLoading(false);
                console.error("Error reading file as base64.");
            };
        }
    };


    const getUserData = () => {
        try {
            apiService.getUserData().then((response) => {
                setUserData(response.data.data);
            });
        } catch (error) {
            setUserData({})
        }
    }
    function getLinkForPlatform(platform: string): string | null {
        if (!userData.socialLinks) {
            return null;
        }

        const foundLink = userData.socialLinks.find(socialLink => socialLink.platform === platform.toLowerCase());
        return foundLink ? foundLink.link : null;
    }

    return (
        <Dashboard headerTitle={t('myProfile')}>
            <div className="main-container">
                <div className='sub-container'>
                    <div className='second-sub-container'>
                        <span className='container-title'>{t('personalInformation')}</span>
                        {/* Profile Section */}
                        <div className="profile-section">
                            {/* Personal Information */}
                            <div className="personal-info">
                                <div className="profile-image">
                                    <img src={userData.photoURL ?? "https://www.shutterstock.com/image-vector/avatar-gender-neutral-silhouette-vector-600nw-2470054311.jpg"} alt="Profile" />
                                    <FileUpload onSelect={(val) => {
                                        if (val) {
                                            handleProfilePicChange(val)
                                        }
                                    }} icon={<BorderlessButton label={t('update')} icon={<EditButton />} color={"#E27C37"} />} />
                                </div>

                                <div className="info-section">
                                    <div className='header-group'>
                                        <h2 className='header-text'>{userData.displayName ?? '-'}</h2>
                                        <BorderlessButton onClick={() => setOpen(true)} label={t('Edit')} icon={<EditButton />} color={"#E27C37"} />
                                        <EditProfile open={open} setOpen={setOpen} userData={userData} />
                                    </div>
                                    <div className="info-group">
                                        <div className='info-block'>
                                            <span className='title'>{t('userRole')}</span>
                                            <span className='value'>{userData.roles ? userData.roles[0] : '-'}</span>
                                        </div>
                                        <div className='info-block'>
                                            <span className='title'>{t('memberSince')}</span>
                                            <span className='value'>{formatDate(userData.createdDate) ?? '-'}</span>
                                        </div>
                                    </div>
                                    <div className='title-container'>
                                        <h3>{t('primaryInformation')}</h3>
                                        <hr className='line' />
                                    </div>
                                    <div className="info-group">
                                        <div className='info-block'>
                                            <span className='title'>{t('contactNumber')}</span>
                                            <span className='value'>{userData.phoneNumber ?? '-'}</span>
                                        </div>
                                        <div className='info-block'>
                                            <span className='title'>{t('emailAddress')}</span>
                                            <span className='value'>{userData.email ?? '-'}</span>
                                        </div>
                                    </div>

                                    <div className='title-container'>
                                        <h3>{t('secondaryInformation')}</h3>
                                        <hr className='line' />
                                    </div>
                                    <div className="info-group">
                                        <div className='info-block'>
                                            <span className='title'>{t('dateofBirth')}</span>
                                            <span className='value'>{formatDate(userData.DOB!) ?? '-'}</span>
                                        </div>
                                        <div className='info-block'>
                                            <span className='title'>{t('Gender')}</span>
                                            <span className='value'>{userData.gender ?? '-'}</span>
                                        </div>
                                        <div className='info-block'>
                                            <span className='title'>{t('nationality')}</span>
                                            <span className='value'>{userData.nationality ?? '-'}</span>
                                        </div>
                                    </div>
                                    <div className='title-container'>
                                        <h3>{t('socialAccounts')}</h3>
                                        <hr className='line' />
                                    </div>
                                    <div className="info-group">
                                        <div className='info-block'>
                                            <span className='title'>LinkedIn</span>
                                            <a href={getLinkForPlatform('linkedin') ?? '/'} className='value'>{extractUsernameFromLink(getLinkForPlatform('linkedin') ?? '') ?? '-'}</a>
                                        </div>
                                        <div className='info-block'>
                                            <span className='title'>Facebook</span>
                                            <a href={getLinkForPlatform('facebook') ?? '/'} className='value'>{extractUsernameFromLink(getLinkForPlatform('facebook') ?? '') ?? '-'}</a>
                                        </div>
                                        <div className='info-block'>
                                            <span className='title'>Instagram</span>
                                            <a href={getLinkForPlatform('instagram') ?? '/'} className='value'>{extractUsernameFromLink(getLinkForPlatform('instagram') ?? '') ?? '-'}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Password Section */}
                    {/* <div className='second-sub-container'>
                        <span className='container-title'>{t('passwords')}</span>
                        <div className="password-section">
                            <p className='detail-box'>{t('updatePassword')}</p>
                            <SecondaryButton onClick={() => { }} label={'Set New Password'} borderButton />
                        </div>
                    </div> */}

                    {/* Documents Section */}
                    {/* <div className='second-sub-container'>
                        <h2 className='container-title'>{t('documents')}</h2>
                        <div className="documents-section">
                            <div className='upload-btn'>
                                <span className='title'>{t('recentlyAdded')}</span>
                                <FileUpload onSelect={(val) => {
                                    if (val) {
                                        handleDocumentChange(val);
                                    }
                                }} icon={<BorderlessButton label={'Upload'} icon={<BorderAdd />} color='#E27C37' />} />
                            </div>
                            <div className='documents-container'>
                                <div className="document-list">
                                    <FileTypeIcon fileType='pdf' fileName='Japan Tour.pdf' date={new Date} size='1.2 MB' />
                                    <FileTypeIcon fileType='doc' fileName='Australia Road Trip.docx' date={new Date} size='1.2 MB' />
                                    <FileTypeIcon fileType='txt' fileName='Switzerland Plan.txt' date={new Date} size='1.2 MB' />
                                </div>
                                <div className='uploaded-list'>
                                    <span className='files-header'>All Files</span>
                                    <FileTable setFileList={() => { }} onDelete={function (id: string): void { }} setLoading={() => { }} fileList={[]} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='second-sub-container'>
                        <span className='container-title'>{t("accountDeletion")}</span>
                        <div className="password-section">
                            <p className='detail-box'>{t('accountDeleteDescription')}</p>
                            <SecondaryButton onClick={function (): void {
                                throw new Error('Function not implemented.')
                            }} label={t("requestAccountDeletion")} redButton borderButton />
                        </div>
                    </div> */}
                </div>
                {/* <ActivityLog /> */}
            </div>
        </Dashboard>
    )
}

export default Profile