import './TemplateCard.scss'
import calendarIcon from "../../assets/images/lead-card/calendar.svg";
import { ItineraryDocument } from '../../helpers/Interfaces';
import { formatDate } from '../../helpers/helperfunctions';
import { MoreVert } from '@mui/icons-material';
import { useContext, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CalenderOrangeSvg from '../../assets/images/Library/CalenderOrangeSvg';
import CategorySvg from '../../assets/images/Library/CategorySvg';
import TagSvg from '../../assets/images/Library/TagSvg';
import { LibraryFunctions } from '../../pages/Dashboard/Library/LibraryFunctions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setItinerary } from '../../store/slices/itinerarySlice';
import { AppDispatch } from '../../store/store';
import { Store } from '../../store/polontoStore';

const TemplateCard = (props: { itinerary?: ItineraryDocument, onClick: CallableFunction, selected?: boolean }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const libraryFunction = new LibraryFunctions()
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [_, loadJson] = useContext(Store);

    const editTemplate = async (templateId: string) => {
        setLoading(true);
        const template = await libraryFunction.editTemplate(props.itinerary);
        if (!template) return
        dispatch(setItinerary(template));
        loadJson(template.documentContent);
        setLoading(false);
        navigate('/dashboard/builder');
    }

    const menuItems = [{ action: editTemplate, text: 'Edit Template' }]

    const handleClick = (event: any) => {
        if (loading) return;
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div onClick={() => props.onClick(props.itinerary)} className={`template-card-outer ${props.selected ? 'active-card' : ''}`}>
            <img style={loading ? { filter: 'blur(1.1px)', opacity: 0.8 } : undefined} className='template-image' src={props.itinerary?.previewUrl} alt="" />
            {loading ? (<div className="loading"></div>) : (<></>)}
            {/* <div onClick={handleClick} className="menu-list">
                {(menuItems.length > 0) ? (<MoreVert classes={{ root: 'menu-icon' }} />) : (<></>)}
            </div> */}
            <div className="template-content">
                <div className="template-name">{props.itinerary?.documentName}</div>
                <hr />
                <div className="data-content">
                    <div className="data-inner c-black-gray">
                        <CalenderOrangeSvg />
                        {formatDate(props.itinerary?.createdDate, 'DD/MM/YYYY')}</div>
                    <div className="data-inner c-black-gray">
                        <CategorySvg />
                        {props.itinerary?.documentCategory}</div>
                </div>
                {/* <div className="data-content">
                    <div className="data-inner c-black-gray">
                        <TagSvg />
                        about us, general pages, english</div>
                </div> */}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose} >
                {menuItems.map((i) => <MenuItem onClick={() => {
                    handleClose();
                    if (i.action) i.action(props.itinerary?.documentId ?? '');
                }}>{i.text}</MenuItem>)}
            </Menu>
        </div>
    )
}

export default TemplateCard

