import { observer } from 'mobx-react-lite';
import { ImagesGrid, SectionTab } from 'polotno/side-panel';
import { getImageSize } from 'polotno/utils/image';
import { useEffect, useState } from 'react';
import { ICustomFile, ItineraryDocument } from '../../../../../helpers/Interfaces';
import { FileUpload as FileUploadIcon, FolderOpenOutlined } from '@mui/icons-material';
import { StoreType } from 'polotno/model/store';
import FileUpload from '../../../../../components/FileUpload/FileUpload';
import BorderlessButton from '../../../../../components/ButtonWithoutBorder/ButtonWithoutBorder';
import EditButton from '../../../../../assets/images/profile/EditButton';
import FirebaseFunctions from '../../../../../helpers/firebaseFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/store';
import { setItinerary } from '../../../../../store/slices/itinerarySlice';

const PhotosPanel = observer(({ store }: { store: StoreType }) => {
    const firebaseFunction = new FirebaseFunctions();
    const [loading, setLoading] = useState<boolean>(false);
    const itinerary: ItineraryDocument = useSelector((state: RootState) => state.itinerary);
    const dispatch = useDispatch<AppDispatch>();

    async function loadImages() {

    }

    useEffect(() => {
        loadImages();
    }, []);

    const onSelectFiles = async (fileList: any) => {
        if (!fileList || fileList.length === 0) return;

        setLoading(true);
        try {
            const newMedia: ICustomFile[] = [];

            // Upload files sequentially to Firebase and add to the newMedia array
            for (const file of fileList) {
                const customFile = await firebaseFunction.addMediaFirebase(file.name, file);
                if (customFile) {
                    newMedia.push(customFile);
                }
            }

            // Update the itinerary document with the new media files
            const updatedMedia = [...(itinerary.media ?? []), ...newMedia];
            const updatedItinerary = { ...itinerary, media: updatedMedia } as ItineraryDocument;

            await firebaseFunction.updateItineraryDocument(itinerary.documentId ?? '', updatedItinerary);

            dispatch(setItinerary(updatedItinerary));
        } catch (error) {
            console.error('Error uploading files or updating itinerary:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <FileUpload loading={loading} onSelect={onSelectFiles} icon={<BorderlessButton loading={loading} label={'Upload from files'} icon={<FolderOpenOutlined color='inherit' />} color={"#E27C37"} />} />
            {(itinerary?.media ?? []).length > 0
                ? (<ImagesGrid
                    images={itinerary?.media}
                    getPreview={(image) => {
                        console.log(image);

                        return image.downloadLink
                    }}
                    onSelect={async (image, pos, element, event) => {
                        const { width, height } = await getImageSize(image.downloadLink);
                        store.activePage.addElement({
                            type: 'image',
                            src: image.downloadLink,
                            width,
                            height,
                            x: pos?.x || 0,
                            y: pos?.y || 0,
                        });
                    }}
                    rowsNumber={2}
                    isLoading={!(itinerary?.media ?? []).length}
                    loadMore={false}
                />)
                : (<p style={{ fontFamily: "var(--primary-font)", textTransform: 'capitalize', margin: '10px 0px' }}>no uploaded images</p>)}

        </div>
    );
});
// define the new custom section
export const CustomPhotoUploadPanel = {
    name: 'Uploads',
    Tab: (props: any) => (
        <SectionTab name="Uploads" {...props}>
            <FileUploadIcon />
        </SectionTab>
    ),
    Panel: PhotosPanel,
};