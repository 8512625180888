import dayjs, { Dayjs } from 'dayjs';
import { IAssignee, ICustomFile, IItineraryOptions, ILead, ILeadTask, ILog, ItineraryDocument, IUser } from '../../helpers/Interfaces';
import { LeadTaskStages } from '../../helpers/types';
import ApiService from '../../services/ApiService';
import { generateUniqueKey } from '../../helpers/helperfunctions';
import { DropdownItemProps } from '../Mui/CustomAutocomplete/CustomAutocomplete';
import { NavigateFunction } from 'react-router-dom';
import { emptyTemplate } from '../../helpers/constants';
import { setItinerary } from '../../store/slices/itinerarySlice';
import FirebaseFunctions from '../../helpers/firebaseFunctions';
// import { setItineraryId } from '../../store/slices/itinerarySlice';


export default class ViewLeadFunctions {
    apiService = new ApiService();
    firebaseFunctions = new FirebaseFunctions();

    addTask = (description: string, lead: ILead, setLeads: CallableFunction, closeNewTask: CallableFunction) => {
        let newLead = lead;
        if (!newLead.task)
            newLead.task = [];

        const task: ILeadTask = {
            id: generateUniqueKey(),
            description: description,
            status: 'PENDING',
            dueDate: dayjs(new Date())
        }

        newLead.task.push(task)

        const data = {
            leadId: lead.id,
            task: task
        }

        this.apiService.addLeadTask(data).then(() => {
            closeNewTask();
            setLeads({ ...newLead });
        });
    }

    updateTaskStage = (taskId: string, stage: LeadTaskStages, lead: ILead, setLead: CallableFunction, refresh: CallableFunction) => {
        let newLead = { ...lead };

        if (!newLead.task) newLead.task = [];

        const _task = newLead.task.find(t => t.id === taskId) as any;

        if (!_task) return;

        _task.status = stage;

        newLead.task = newLead.task.map((task) => {
            if (task.id === taskId) {
                return { ..._task };
            }
            return task;
        });

        if (_task.assignees) {
            let assignees = _task.assignees.map((assignee: any) => assignee.id);
            _task.assignees = assignees;
        }

        const data = {
            leadId: lead.id,
            task: _task
        };

        this.apiService.updateLeadTask(data).then(() => {
            setLead({ ...newLead });
            refresh();
        });

    }

    updateTaskStageDate = (taskId: string, date: Dayjs, lead: ILead, setLead: CallableFunction, refresh: CallableFunction) => {
        let newLead = { ...lead };

        if (!newLead.task) newLead.task = [];

        const _task = newLead.task.find(t => t.id === taskId) as any;

        if (!_task) return;

        _task.dueDate = date;

        newLead.task = newLead.task.map(task => {
            return task.id === taskId ? { ..._task } : task;
        });

        if (_task.assignees) {
            _task.assignees = _task.assignees.map((assignee: any) => assignee.id);
        }

        const data = {
            leadId: lead.id,
            task: _task,
            action: 'date'
        };

        this.apiService.updateLeadTask(data).then(() => {
            setLead({ ...newLead });
            refresh();
        });

    }

    updateLeadStage = (leadId: string, stage: string, refresh: CallableFunction) => {
        const data = {
            leadId: leadId,
            stage: stage
        }

        this.apiService.updateLeadStage(data).then(() => { refresh(); });
    }

    deleteLeadTask = (leadId: string, taskId: string, refresh: CallableFunction, lead: ILead, setLead: CallableFunction) => {
        const data = {
            leadId: leadId,
            taskId: taskId
        }

        let newLead = lead;
        newLead.task = newLead.task?.filter(t => t.id != taskId);

        this.apiService.deleteLeadTask(data).then(() => {
            setLead({ ...newLead });
            refresh();
        });
    }

    deleteLead = (leadId: string, refresh: CallableFunction) => {

        const data = {
            leadId: leadId
        }

        this.apiService.deleteLead(data).then(() => { refresh() });
    }

    getLogs = (leadId: string, setLogs: CallableFunction) => {

        const data = {
            leadId: leadId
        }

        try {
            this.apiService.getAllLogs(data).then((response) => {
                let logs = response.data.logs as ILog[];

                logs.reverse();
                return setLogs(logs);
            });
        } catch (error) {
            setLogs([])
        }
    }

    addTaskAssignee = async (lead: ILead, setLead: CallableFunction, taskId: string, assignee: IAssignee, refresh: CallableFunction) => {
        if (!lead.task) return;

        const task = lead.task?.find(t => t.id === taskId);

        if (!task) return;

        if (!task.assignees) {
            task.assignees = [];
        }

        const assigneeIndex = task.assignees.findIndex(a => a.id === assignee.id);

        if (assigneeIndex !== -1) {
            task.assignees.splice(assigneeIndex, 1);
        } else {
            task.assignees.push(assignee);
        }

        const updatedTasks = lead.task?.map(t => t.id === taskId ? task : t);

        const data = {
            leadId: lead.id,
            assigneeId: assignee.id,
            taskId: taskId
        }

        this.apiService.handleTaskAssignees(data).then(() => {
            setLead({
                ...lead,
                task: updatedTasks
            });
            return refresh();
        }).catch(() => {
            console.log('failed to assign user');
        });

    }

    addLeadAssignee = (lead: ILead, setLead: CallableFunction, assignee: DropdownItemProps, refresh: CallableFunction) => {
        const localLead = lead;

        if (!localLead.assignees) {
            localLead.assignees = [];
        }

        const assignIndex = localLead.assignees.findIndex(t => t.id === assignee.id);

        if (assignIndex !== -1) {
            localLead.assignees.splice(assignIndex, 1);
        } else {
            localLead.assignees.push({ name: assignee.label, id: assignee.id.toString() });
        }

        const data = {
            leadId: lead.id,
            assigneeId: assignee.id,
        }

        this.apiService.handleLeadAssignees(data).then(() => {
            setLead({ ...localLead });
            return refresh();
        }).catch(() => {
            console.log('failed to assign user');
        });

        setLead({ ...localLead });
    }

    getLeadItineraries = (leadId: string, setItineraries: CallableFunction, setItineraryLoading: CallableFunction) => {
        const data = {
            leadId: leadId
        }
        setItineraryLoading(true);
        this.apiService.getLeadViewItineraries(data).then((response) => {
            const itineraries = response.data.data as ItineraryDocument[];

            if (itineraries) {
                const categorizedItineraries = itineraries.reduce(
                    (acc, itinerary) => {
                        itinerary.publish ? acc.sent.push(itinerary) : acc.draft.push(itinerary);
                        return acc;
                    },
                    { sent: [] as ItineraryDocument[], draft: [] as ItineraryDocument[] }
                );

                setItineraries(categorizedItineraries);
            }
            return setItineraryLoading(false);
        }).catch(() => {
            setItineraries({ draft: [], sent: [] });
        }).finally(() => {
            setItineraryLoading(false);
        });
    }

    handleEditItinerary = async (itineraryId: string, dispatch: any, navigate: NavigateFunction, loadJson: CallableFunction) => {
        if (itineraryId) {
            const template = await this.firebaseFunctions.getItineraryLead(itineraryId);
            if (!template) return;

            dispatch(setItinerary(template));
            loadJson(template.documentContent);
            navigate('/dashboard/builder');
        }
    }

    handleGenerateItinerary = async (lead: ILead, setLoading: CallableFunction, navigate: NavigateFunction, dispatch: any, loadJson: CallableFunction) => {
        setLoading(true);

        const itineraryDocument: ItineraryDocument = {
            documentId: generateUniqueKey(),
            documentType: 'itinerary',
            documentName: `New Itinerary for ${lead.user.name}`,
            documentContent: emptyTemplate,
            createdDate: new Date().toISOString(),
        }

        const data = {
            leadId: lead.id, itinerary: itineraryDocument
        };

        await this.apiService.createLeadItineraries(data);
        loadJson(emptyTemplate);

        dispatch(setItinerary(itineraryDocument));
        navigate('/dashboard/builder');

        setLoading(false);

    }
    updateLeadMedia = async (mediaFiles: ICustomFile[], leadId?: String) => {
        const data = {
            leadId: leadId,
            mediaFiles: mediaFiles
        }
        console.log(data);
        this.apiService.updateLeadMedia(data).then(() => {
            console.log("updated media");
        }).catch(() => {
            console.log('failed to update media');
        });
    }
    onFilesSelected = async (selectedFiles: FileList, setUploading: CallableFunction, uploadedFiles: ICustomFile[], setUploadedFiles: CallableFunction, leadId?: string) => {
        if (selectedFiles) {
            const filesArray = Array.from(selectedFiles);
            setUploading(true);
            let tempFileList: ICustomFile[] = uploadedFiles
            for (const file of filesArray) {
                const result = await this.firebaseFunctions.addMediaFirebase(file.name, file);
                if (result) {
                    tempFileList.push(result);
                }
            }
            await this.updateLeadMedia(tempFileList, leadId).then(() => {
                setUploadedFiles(tempFileList);
            }).catch(() => {
                console.log('failed to update media');
            }).finally(() => setUploading(false));
        }
    };
    onFileDelete = async (fileId: string, uploadedFiles: ICustomFile[], setUploadedFiles: CallableFunction, leadId?: string ) => {
        let tempFileList = uploadedFiles.filter(file => file.id !== fileId);
        await this.updateLeadMedia(tempFileList, leadId).then(() => {
            setUploadedFiles(tempFileList);
        }).catch(() => {
            console.log('failed to update media');
        });
    }
}